import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { handleFetch } from '@martrust/ui';
import { isAnyOf } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/sveltekit';
import {
	handleErrorWithSentry,
	httpClientIntegration,
	replayIntegration,
} from '@sentry/sveltekit';
import { goto } from '$app/navigation';
import * as publicEnv from '$env/static/public';
import { exchangeRefreshToken } from '$lib/aws/cognito';
import { startListening, store } from '$lib/stores/';
import {
	biometricLockEnabled,
	biometricLockUnlocked,
	isLocked,
} from '$lib/stores/biometric-lock';
import {
	initialiseBiometricLock,
	unlockWithBiometrics,
} from '$lib/stores/biometric-lock/mobile';

const enableSentry = publicEnv.PUBLIC_ENABLE_SENTRY === 'true';
if (enableSentry) {
	Sentry.init({
		environment: publicEnv.PUBLIC_ENV_CONFIG ?? 'dev',
		dsn: publicEnv.PUBLIC_SENTRY_DSN ?? '',
		tracesSampleRate: 1.0,
		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 1.0,
		// If you don't want to use Session Replay, just remove the line below:
		integrations: [replayIntegration(), httpClientIntegration()],
		ignoreErrors: [
			// Fetch API generic errors
			/^Network Error/,
			/^TypeError: Failed to fetch/,
			/^TypeError: Load failed/, // capacitor equivalent of the above
			// Asset loading (possibly cache-related)
			/^Failed to fetch dynamically imported module/,
			/^Unable to preload CSS for/,
			/^Importing a module script failed/,
		],
	});
}

export async function init(): Promise<void> {
	if (publicEnv.PUBLIC_ADAPTER !== 'mobile') {
		return;
	}

	startListening({
		matcher: isAnyOf(biometricLockEnabled, biometricLockUnlocked),
		effect: async (_action) => {
			await exchangeRefreshToken(handleFetch);
		},
	});

	startListening({
		actionCreator: unlockWithBiometrics.fulfilled,
		effect: async (_action, { getState }) => {
			const { biometricLock } = getState();

			if (isLocked(biometricLock)) {
				if (window.location.pathname !== '/biometric-unlock') {
					await goto('/biometric-unlock');
				}
			}

			await SplashScreen.hide();
		},
	});

	const unlockOrHideSplash = async () => {
		const { biometricLock } = store.getState();

		if (isLocked(biometricLock)) {
			if (window.location.pathname !== '/biometric-unlock') {
				await SplashScreen.show({
					autoHide: false,
				});
			}

			store.dispatch(unlockWithBiometrics());
		} else {
			await SplashScreen.hide();
		}
	};

	const onResume = async () => {
		if (Capacitor.getPlatform() === 'android') {
			const { biometrics } = store.getState();

			// On android biometrics forces app into background so this is to filter out these 'false' resume triggers.
			if (biometrics.isAvailable && biometrics.authenticating) {
				return;
			}
		}

		await unlockOrHideSplash();
	};

	await App.addListener('resume', onResume);

	await store.dispatch(initialiseBiometricLock());

	await unlockOrHideSplash();
}

// init function only supported from version 2.10 so we need to patch untill we update
let initialised = false;
if (initialised === false) {
	init().then(
		() => {
			initialised = true;
		},
		(error) => {
			const { name, message, cause } = error;
			console.error('initialising', { name, message, cause });
		},
	);
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
